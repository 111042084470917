import type { BlockStatsHighlight } from 'types/block-stats_highlight';
import React from 'react';
import { Button, Heading, Paragraph, Wrap } from '@leafwell/components';
import Figure from './figure';
import HeadingBody from './heading-body';

const StatsHighlight: React.FC<BlockStatsHighlight> = ({
  title,
  description,
  image,
  button,
  items,
}) => {
  return (
    <Wrap className="lg:mt-20">
      <div className="grid md:grid-cols-2 lg:grid-cols-13 items-center gap-10 xl:gap-x-6">
        <div className="grid gap-y-4 md:gap-y-10 justify-items-start lg:col-span-5 xl:col-span-6">
          <HeadingBody level="2" title={title} />
          <Paragraph
            dangerouslySetInnerHTML={{ __html: description }}
            size="large"
            className="lg:leading-relaxed"
          />
          <Button
            href={button.url}
            label={button.title}
            rounded={true}
            target={button.target}
            variant="gradient"
          />
        </div>
        <Figure
          {...image}
          className="hidden lg:block lg:col-span-4 xl:col-span-3 lg:w-full-36 lg:justify-self-end lg:-mt-12"
        />
        <section className="grid auto-rows-auto	gap-6 lg:col-span-4">
          {items.map(({ image, label, value }) => (
            <article
              className="bg-gray-200 rounded-lg p-6 grid grid-cols-2-auto gap-x-10"
              key={image.id}
            >
              <Figure {...image} className="w-10 sm:w-12" />
              <div className="flex flex-col">
                <HeadingBody level="3" title={value} />
                <Paragraph size="large">{label}</Paragraph>
              </div>
            </article>
          ))}
        </section>
      </div>
    </Wrap>
  );
};

export default StatsHighlight;
